<template>
  <section>
    <uspCartao titulo="Lista de documentos emitidos pela Universidade de São Paulo">
      <template #corpo>
        <uspSkeleton v-if="carregando">
          <div 
            v-for="index in 10" 
            :key="index"
          >
            <div class="w-30" />
            <div class="w-15" />
            <div class="w-55" />
          </div>
        </uspSkeleton>
        <uspTabela
          v-else
          :dados="documentos"
          :campos="campos"
        />
      </template>
    </uspCartao>
  </section>
</template>

<script>
import WebdocServico from "../dominio/WebdocServico.js";
import eventBus from '@/eventBus';

const api = WebdocServico.build({});

export default {
  name: "WebdocTiposDocumentos",

  data() {
    return {
      documentos: [],
      carregando: false,
      erro: "",
      campos: [
        {
          campo: 'tipdocofe',
          nome: 'Nome do documento',
          ordenavel: true,
        },
        {
          campo: 'areneg',
          nome: 'Área',
          ordenavel: true,
        },
        {
          campo: 'dsctipdoc',
          nome: 'Descrição',
          ordenavel: false,
        },
      ]
    }
  },

  watch: {
    erro(newValue) {
      if (newValue) {
        eventBus.mostrarMensagemToast({
              titulo: "Erro",
              mensagem: this.erro,
              variante: 'danger'
            })
        this.erro = "";
      }
    },
  },

  mounted() {
    this.listar();
  },
  
  
  methods: {
    listar() {
      const self = this;
      self.carregando = true;
      api.listarTipoDocumentoEmitido()
        .then((resp) => {
          self.documentos = resp;
        })
        .catch((erro) => {
          self.documentos = [];
          self.erro = ((erro || "").mensagem || erro);
        })
        .finally(() => {self.carregando = false;});
    },
  },

}
</script>