import { PortalServico } from '@/utils/';

/**
 * @type {WebdocServico}
 */
export default class WebdocServico extends PortalServico {

  obterDocumento(params) {
    return this.download("wsportal/id-digital/documentos/" + params.codaucdoc + (params.idioma ? ("?idioma=" + params.idioma) : ""), {"X-Recaptcha-Token" : params.token || ""});
  }

  listarTipoDocumentoEmitido() {
    return this.get("wsportal/id-digital/tipos-documentos");
  }
}